import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import kebabCase from 'lodash/kebabCase';

// Components
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import RecentReviews from '../components/RecentReviews';
import PopularArticles from '../components/PopularArticles';
import './tags.css';

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <Layout>
    <SEO
      title="Tags"
    />
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <RecentReviews className="d-none d-md-block" sideBar />
          <PopularArticles className="d-none d-md-block" sideBar />
        </div>
        <div className="col-sm-12 col-md-9">
          <h1>Tags</h1>
          <div className="flex-container">
            {group.map((tag) => (
              <div key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue}
                  {' '}
(
                  {tag.totalCount}
)
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired,
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

TagsPage.defaultProps = {
  data: {},
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
